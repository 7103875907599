export enum SOCIAL {
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    YOUTUBE = "youtube",
    TWITTER = "twitter",
    LINKEDIN = "linkedin",
    KUMU = "kumu",
    VIBER = "viber_community",
    TIKTOK = "tiktok",
}

export enum SECTION {
    FEATUREDVIDEO = "featured_video",
    LEFTSIDE = "left_side",
    CENTERTOP = "center_top",
    CENTERBOTTOM = "center_bottom",
    RIGHTSIDE = "right_side",
    LEFTALIGN = "left_align",
    CENTERALIGN = "center_align",
    RIGHTALIGN = "right_align",
}

export enum SECTIONSTATUS {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
}

export enum STATUS {
    PUBLISHED = "PUBLISHED",
    ARCHIVED = "ARCHIVED",
    DELETED = "DELETED",
    DRAFT = "DRAFT",
}

export enum HEROBANNERTYPE {
    VIDEO = "VIDEO",
    IMAGE = "IMAGE",
}

export enum PLATFORM {
    MOBILE = "MOBILE",
    WEBSITE = "WEBSITE"
}